$primary: #0081c6;
$accordion-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-border-color: transparent;
$accordion-button-focus-box-shadow: transparent;
$link-decoration: none;
$link-hover-decoration: underline;
$min-contrast-ratio:   4;

@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons";

img {
  &.lazy {
    aspect-ratio: 1;
  }
}

.cursor-pointer {
  cursor: pointer;
}